import React, { useState, useEffect } from "react";
import Spinner from "react-text-spinners";

const Contact: React.FC = () => {
    const [email, setEmail] = useState(null);
    useEffect(() => {
        setTimeout(() => {
            fetch("/data.json")
                .then((res) => res.json())
                .then((data) => setEmail(atob(data["data"])));
        }, 1000);
    }, []);

    return (
        <span>
            {email ? (
                <a href={`mailto:${email}`}>{email}</a>
            ) : (
                <Spinner backgroundColor="#212121" theme="dots2" />
            )}
        </span>
    );
};

export default Contact;
